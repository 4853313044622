@import 'helpers';
@import 'item';

.hierarchy-container {
    background-color: $hierarchy-background-color;

    .title {
        color: $hierarchy-primary-color;
    }

    .item-wrapper {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: $hierarchy-item-padding;
        box-sizing: border-box;
        margin-top: -$hierarchy-item-wrapper-border-width;
        border: $hierarchy-item-wrapper-border-width dashed transparent;

        & +.item-wrapper {
            margin-left: $hierarchy-horizontal-spacing;
        }

        &::before {
            content: " ";
            position: absolute;
            pointer-events: none;
            top: -$hierarchy-item-padding;
            left: #{-$hierarchy-item-wrapper-border-width - $hierarchy-horizontal-spacing / 2};
            width: calc(100% + #{$hierarchy-item-wrapper-border-width * 2 + $hierarchy-horizontal-spacing});
            height: $hierarchy-connector-size;
            background-color: $hierarchy-primary-color;
        }

        &:first-child::before {
            left: 50%;
            /* one extra pixel from item-wrapper border */
            width: calc(50% + #{$hierarchy-item-wrapper-border-width + $hierarchy-horizontal-spacing / 2});
        }

        &:last-child::before {
            /* one extra pixel from item-wrapper border */
            left: #{-$hierarchy-item-wrapper-border-width - $hierarchy-horizontal-spacing  / 2};
            width: calc(50% + #{$hierarchy-item-wrapper-border-width + $hierarchy-horizontal-spacing / 2});
        }

        &:only-child::before {
            content: none;
        }
    }

    .items {
        position: relative;
        display: flex;
        align-items: flex-start;
        overflow: hidden;
        overflow-x: auto;
    }

    .item {        
        & +.items {
            padding-top: $hierarchy-vertical-connector-height;

            &:empty {
                padding-top: 0;
                height: 0;
                margin-top: -$hierarchy-item-padding;
            }
        }

        &::before, &::after {
            content: " ";
            position: absolute;
            pointer-events: none;
            width: $hierarchy-connector-size;
            background-color: $hierarchy-primary-color; 
            left: calc(50% - #{$hierarchy-item-wrapper-border-width});
        }
        
        &::before {
            top: -$hierarchy-vertical-connector-height;
            height: $hierarchy-vertical-connector-height;
        }

        &::after {
            bottom: -$hierarchy-vertical-connector-height;
            height: $hierarchy-vertical-connector-height;
        }

        &:not(.has-children)::after {
            content: none;
        }
    }

    /* Round corners of horizontal connectors */
    .items > .item-wrapper:first-child:not(:only-child) > .item::before {
        border-top-left-radius: $hierarchy-connector-size;
    }
    .items > .item-wrapper:last-child:not(:only-child) > .item::before {
        border-top-right-radius: $hierarchy-connector-size;
    }

    /* Extra padding for the root */
    > .items > .item-wrapper {
        padding: $hierarchy-root-item-padding;
    }
    
    /* No vertical connector for the root node */
    > .items > .item-wrapper,
    /* No top connector for the root nodes */
    > .items > .item-wrapper > .items > .item-wrapper > .item {
        &::before {
            content: none;
        }
    }
    /* No border fix margin for the root */
    > .items > .item-wrapper,
    > .items > .item-wrapper > .items > .item-wrapper {
        margin-top: 0;
    }

    &[data-allow-drag-and-drop] {

        .item-wrapper.is-mouse-over {
            cursor: move;
            background-color: $hierarchy-item-wrapper-hover-background-color;

            .item {
                background-color: $hierarchy-item-hover-background-color;
            }

            /* Change color of all vertical connectors */
            .item-wrapper, .item {
                &::before, &::after {
                    background-color: $blue-500;
                }
            }
        }

        .item-wrapper {
            &.is-being-dragged {
                .item {
                    background-color: $hierarchy-item-is-being-dragged-color;
                }

                /* Change color of all vertical connectors */
                .item-wrapper, .item {
                    &::before, &::after {
                        background-color: $hierarchy-item-is-being-dragged-color;
                    }
                }
            }
            
            &.is-active-drag-target {
                background-color: transparentize($hierarchy-item-is-valid-drag-target-color, 0.75);

                .item {
                    background-color: $hierarchy-item-is-valid-drag-target-color;
                }
            }

            &.is-invalid-drag-target {

            }

            &.was-moved {
                animation: item-was-moved 2s;                
            }

            &.was-moved-from {
                animation: item-was-moved-from 2s;                
            }
        }

        &.is-item-being-dragged {
            .item-wrapper {
                border-color: rgba(255, 255, 255, 0.1);
            }
        }
    }
}