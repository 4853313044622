$color-bluugo-blue: #3a96c7;
$color-green: #78BE20;
$color-blue: #009FDF;
$color-grey: #c3c5c6;
$color-dark-grey: #888b8d;
$color-hover-grey: #565b5d;
$color-red: #ce1212;
$color-yellow: #ffd100;
$color-yellow-light: #fff3bf;
$color-yellow-text: #b09517;
$color-background: #323536;