.available-reports {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;

    .report-item {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 150px;
        width: 150px;
        text-align: center;

        .fa {
            font-size: 35px;
        }

        .report-name {
            margin-top: 16px;
            display: block;
        }

        &:hover {
            background-color: #f9f9f9;
        }
    }
}

.preview-table {
    tr.summary-row td {
      border-top: 1px solid #a2a2a2;
    }
}

.technician-row td {
    border-top: 1px solid #b3b3b3 !important;
    font-weight: bold;
}