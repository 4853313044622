@keyframes is-drag-target {
    0% {
        background-color: transparent;
    }

    75% {
        background-color: transparentize($green-500, 0.75);
    }

    100% {
        background-color: transparent;
    }
}

@keyframes item-was-moved-from {
    from {
        background-color: transparentize($red-500, 0.75);
    }
    to {
        background-color: transparent;
    }
}

@keyframes item-was-moved {
    from {
        background-color: transparentize($orange-500, 0.75);
    }
    to {
        background-color: transparent;
    }
}
