
#calendar-loading-icon {
    will-change: opacity;
    transition: all 0.3s ease-in-out;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    font-size: 64px;

    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.75);


    .is-visible {
        opacity: 1;
        visibility: visible;
    }

    &:not(.is-visible) {
        opacity: 0;
        visibility: hidden;
    }
}