/* Used for calculations based on the golden ratio. */
$phi: 1.6180339887498948482;

/* Config */
$hierarchy-primary-color: $grey-800 !default;
$hierarchy-background-color: #ffffff !default;

$hierarchy-connector-size: 2px !default;
$hierarchy-horizontal-spacing: 8px !default;
$hierarchy-item-padding: 8px !default;
$hierarchy-item-wrapper-border-width: 1px !default;
$hierarchy-item-wrapper-hover-background-color: rgba(0, 0, 0, 0.1) !default;

$hierarchy-item-background-color: $grey-200 !default;
$hierarchy-item-hover-background-color: $blue-500 !default;
$hierarchy-item-is-valid-drag-target-color: $green-500 !default;
$hierarchy-item-is-being-dragged-color: $orange-500 !default;

/* Derived */
$hierarchy-vertical-connector-height: $hierarchy-item-padding * 2 !default;
$hierarchy-root-item-padding: $hierarchy-item-padding * 10 !default;
