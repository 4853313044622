
.hierarchy-container .item {        
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    background-color: $hierarchy-item-background-color;
    padding: 16px;
    margin-bottom: $hierarchy-item-padding;
    box-sizing: border-box;
    border-radius: 4px;
    $width: 120px;
    width: $width;
    height: $width / $phi;
}