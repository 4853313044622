@import 'colors';
@import 'reporting';
@import 'integration';
@import 'hierarchy-view/all';
@import './maintenance/frontend/sass/app.scss';

$color-sidebar-bg: #2f2f2f;
$color-sidebar-item-bg-active: $color-bluugo-blue;
$color-sidebar-item-bg-hover: #3f5967;
$color-sidebar-item-active: #000000;
$color-sidebar-item: #fff;

#wrapper {
    background-color: $color-sidebar-bg;
}

.navbar-default {
    .nav-header a,
    .nav > li > a {
        color: $color-sidebar-item;
    }
}

.navbar-default .nav > li.active,
.navbar-default .nav > li.is-current {
    &, > a:hover, > a:focus {
        background-color: $color-sidebar-item-bg-active;
        border-color: #206d96;
    }

    a {
        color: $color-sidebar-item;
    }
}

.nav.nav-tabs li {
    background: none;
    border: none;
}

.navbar-default .nav>li>a:hover, .navbar-default .nav>li>a:focus {
    background-color: $color-sidebar-item-bg-hover;
    color: $color-sidebar-item;
}

.nav-second-level li {
    background: #484848;

    &.active, &.is-current {
        background-color: #206d95 !important;
    }
}

.nav-second-level li:last-child {
    margin-bottom: 0;
}

.navbar-default .nav-header .dropdown-menu a {
    color: $grey-800;
}

.ibox-title-overflow {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: normal;
    width: 85%;
    display:inline-block;
}

.db-chart-btns {
    padding-right: 8px;
}

.db-tooltip {
    position: 'absolute';
    display: 'none';
    border: '1px solid #fdd';
    padding: '2px';
    background-color: '#fee';
    opacity: 0.80;
}

.db-tooltip .db-tooltip-text {
    visibility: hidden;
    width: auto;
    bottom: 35%;
    left: -10%;
    margin-left: -50%;
    background-color: #428bca;
    color: #fff;
    text-align: center;
    padding: 10px;
    border-radius: 5px;
    position: absolute;
    z-index: 1;
    opacity: 0;
    transition: opacity 1s;
    max-width: 500px;
}

.db-tooltip .db-tooltip-text-extra {
    bottom: 35px;
    top: -100px;
    left: -43%;
}

.db-tooltip .db-tooltip-text-extra-2 {
    top: -60%;
    bottom: 100%;
    left: 55%;
    right: 5%;
}

.db-tooltip .db-tooltip-text-extra-3 {
    top: -25%;
    bottom: 100%;
    left: 55%;
    right: 5%;
}

.db-info-icon {
    font-size: 22px;
    position: relative;
    left: 0;
    bottom: 0;
    color: #8e9eab;
}

.db-info-icon-extra {
    left: 4px;
}

.db-order-box {
    padding-bottom: 33px;
}

.ibox-two-line-header {
    float: initial !important;
}

#waiting-time-chart-tooltip {
    margin-top: -43px;
}

#db-completed-orders-month-chart,
#db-invoiced-orders-month-chart,
#db-invoiced-working-hours-month-chart,
#db-on-schedule-chart {
    width: 50%;
    height: 100px
}

// Reset overridden styles for newer charts based on ApexCharts
#db-completed-orders-month-chart, #db-invoiced-orders-month-chart, #db-invoiced-working-hours-month-chart, #db-on-schedule-chart {
    width: initial;
    height: initial;
}

#db-invoicing-chart,
#db-provisioning-chart {
    width: 100%;
    height: 100px;
}

#db-completed-orders-month-legend,
#db-invoiced-orders-month-legend,
#db-invoiced-working-hours-month-legend,
#db-invoicing-legend {
    padding-bottom: 10px;
}

.db-invoicing-texts {
    padding-top: 40px;
}

.db-font-20 {
    font-size: 2.0em;
}

.db-font-12 {
    font-size: 1.27em;
}

.db-div-order-box {
    font-size: 2.0em;
}

.object-image {
    background: #fff;
}

.is-hidden {
    visibility: hidden;
}

.is-display-none {
    display: none;
}

.btn-maintenance-plan-order-create {
    margin-top: 23px;
}

.has-whitespace-no-wrap {
    white-space: nowrap;
}

.has-white-space-pre-wrap {
    white-space: pre-wrap;
}

/**
 * FIXME: Not sure which projects this affects so I did just an app specific override - Vp
 */
.model-change-log-item {
    display: inline-block;
    margin: 0;
    border-radius: 0;
    background-color: initial;
    color: #2d7ba7;
    padding: 0;
}

/**
 * Pricing related
 * TODO: Should be moved to own file?
 */
 .tc-pricing-confirm-item {
     float: left;
     width: 99%;
     padding: 8px;
     margin-right: 1%;
     border-bottom: 1px solid #ddd;

     sup {
         top: -0.3em;
         color: #888;
     }
 }

 .tc-error-list {
     list-style: none;
     background: #ffdfe5;
     padding: 16px;
     margin: 0;
     color: #bd2b21;
 }

 .text-right-desktop {
    text-align: inherit;
 }

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
    .tc-pricing-confirm-item {
        width: 49%;
    }
}

// Desktop devices (992 px and up)
@media (min-width: 992px) {
    .text-right-desktop {
        text-align: right;
    }
}

// Large devices (desktops, 992px and up)
@media (min-width: 1200px) { 
    .tc-pricing-confirm-item {
        width: 32%;
    }
}

.order-sub-item-icon {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 1.5em;
    height: 1.5em;
    background-color: #9e9e9e;
    color: #ffffff;
    border-radius: 50%;
}

.order-signature {
    position: relative;
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    max-width: 512px;
    height: 182px;
    border: 1px solid $grey-300;

    .print-name {
        background-color: rgba(255, 255, 255, 0.75);
        padding: 0.25em 0.5em;
        position: absolute;
        right: 0;
        bottom: 0;
    }
}

.invoice-row-wrapper {
    padding-bottom: 8px;
    margin-bottom: 8px;

    &:last-of-type {
        border-bottom: none;
    }
}

pre {
    white-space: pre-line;
}

#dashboard-loading-overlay-parent {
    position: relative;
}

#dashboard-loading-overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 100;
    background-color: rgba(255, 255, 255, 0.75);
    will-change: opacity;
    animation: dashboard-loading-overlay-enter 0.5s;

    &:not(.is-visible) {
        display: none;
    }

    &.is-exiting {
        animation: dashboard-loading-overlay-exit 0.5s;
    }

    .overlay-loading-spinner {
        position: absolute;
        top: 256px;
        left: 50%;
        width: 128px;
        height: 128px;
        border: 8px solid $color-bluugo-blue;
        border-left-color: transparent;
        border-radius: 50%;
        animation: dashboard-loading-overlay-spinner 1s infinite linear;
    }
}

@keyframes dashboard-loading-overlay-spinner {
    from {
        transform: translateX(-50%) rotate(0deg);
    } 
    to {
        transform: translateX(-50%) rotate(360deg);
    }
}

@keyframes dashboard-loading-overlay-enter {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
@keyframes dashboard-loading-overlay-exit {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

.invoice-footer-actions {
    .btn {
        margin-bottom: 4px;
    }
}

.order-form-steps-hidden-container {
    width: auto;
    display: inline-block;
    visibility: hidden;
    position: fixed;
    overflow: auto;
}

a.btn.disabled {
    opacity: 0.75;
    cursor: not-allowed;
}

.allow-pointer-events {
    pointer-events: all !important;
}

.p-a-none {
    padding: 0 !important;
}

.font-normal {
    font-weight: 400;
}